import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { BasicResult } from '../models/basic-result';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor(private httpClient: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    observe: 'response' as 'response',
    responseType: 'blob' as const,
    withCredentials: true,
  };

  exportAuditResults(content:any): any {
    return this.httpClient.post(`${environment.apiUrl}export/auditresults`,content, this.httpOptions).pipe(
      map(res => {        
        let url = window.URL.createObjectURL(res.body!);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = "AuditResults.xlsx";
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();

        return res.body;
      })
    );
  }

  exportFullAudit(content:any): any {
    return this.httpClient.post(`${environment.apiUrl}export/auditfullresult`,content, this.httpOptions).pipe(
      map(res => {
        let url = window.URL.createObjectURL(res.body!);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = "AuditOutput.xlsx";
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();

        return res.body;
      })
    );
  }

  exportActionList(content:any) :any{
    return this.httpClient.post(`${environment.apiUrl}export/auditactionlist`,content, this.httpOptions).pipe(
      map(res => {
        let url = window.URL.createObjectURL(res.body!);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = "ActionList.xlsx";
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();

        return res.body;
      })
    );
  }
}
