import { BasicResult } from "../basic-result";

export class UserSettingsPermissionsResult extends BasicResult{
    ShowUsers: boolean = false;
    ShowAudits: boolean = false;
    ShowLocations: boolean = false;
    constructor(values: Object = {}) {
        super();
        Object.assign(this, values);
    }
}