import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Question } from 'src/app/core-module/models/Audits/question';
import { QuestionAnswer } from 'src/app/core-module/models/Audits/question-answer';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
})
export class RadioButtonComponent {

  @Input() currentQuestion: Question;
  @Output() currentQuestionChange = new EventEmitter<Question>();

  ngOnChanges() {
    this.setDefault()
    
  }

  setDefault() {
    if(!this.currentQuestion.CompletionInfo.QuestionAnswered){
        if (this.currentQuestion.QuestionAnswers.some(e => e.IsDefault)) {
        this.currentQuestion.QuestionAnswers.find(f => f.IsDefault)!.Selected = true;
      }
    }else{
      this.currentQuestion.QuestionAnswers.forEach(e=>{
        if(this.currentQuestion.CompletionInfo.Answer == e.QuestionAnswerID){
          e.Selected = true;
        }else{
          e.Selected = false;
        }
      })
     
    }
    this.performUpdate();
  }
  updateAnswer(currentAnswer: QuestionAnswer) {
    this.currentQuestion.QuestionAnswers.forEach(e => e.Selected = false);
    currentAnswer.Selected = true;
    this.currentQuestion.CompletionInfo.Answer = currentAnswer.QuestionAnswerID;
    this.currentQuestion.CompletionInfo.QuestionAnswered = true;
    this.performUpdate();
  }
  performUpdate() {
    this.currentQuestionChange.emit(this.currentQuestion);
  }

  resetQuestion(){
    this.currentQuestion.QuestionAnswers.forEach(e => e.Selected = false);
    this.currentQuestion.CompletionInfo.Answer = null;
    this.currentQuestion.CompletionInfo.QuestionAnswered = false;
    this.performUpdate();
  }
}
