import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { BasicUser } from 'src/app/core-module/models/basic-user';
import { NewUserResult } from 'src/app/core-module/models/UserManagement/new-user-result';
import { SaveUser } from 'src/app/core-module/models/UserManagement/save-user';
import { Toastr } from 'src/app/core-module/models/Utils/toastr';
import { ToastrService } from 'src/app/core-module/services/toastr.service';
import { UserManagementService } from 'src/app/core-module/services/usermanagement.service';
import { UtilService } from 'src/app/core-module/services/util.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrl: './user-profile.component.scss'
})
export class UserProfileComponent {

  public editingUser:SaveUser = new SaveUser();
  public originalUser:SaveUser = new SaveUser();

  public isEditingName:boolean = false;
  public isEditingEmail:boolean = false;
  public isSaving:boolean = false;

  public isEditing:boolean = false;

  constructor(private UMS:UserManagementService, public router:Router, public toastr:ToastrService,private util:UtilService){
    UMS.getUserProfile().pipe(tap((a) => { this.originalUser = new SaveUser(a);this.setupUser(); })).subscribe();
  }
  setupUser(){
    this.editingUser = new SaveUser(this.originalUser);
  }
  reset(){
    this.setupUser();
  }
  updateEditing(){
    if(this.isEditingName || this.isEditingEmail){
      this.isEditing = true;
    }else{
      this.isEditing = false;
    }
  }
  validateUser(){
    //validate
    var valid = true;
    if(this.editingUser.DisplayName.length == 0){
      valid= false;      
      this.toastr.addToastr(new Toastr({Message: "The displayname entered is invalid. Please try another.", Type: 3}));
    }
    if(this.editingUser.Email.length == 0 || !this.util.validEmail(this.editingUser.Email)){
      valid = false;
      this.toastr.addToastr(new Toastr({Message: "The email address entered is invalid.", Type: 3}));
    }
    if(valid){
      this.save();
    }

  }

  save(){
    this.isSaving = true;
     this.UMS.saveUserProfile(this.editingUser).pipe(
      tap((a) => { 
        this.handleSaveReturn(a);
        this.setupUser();
        this.isSaving = false;
        
      })
    ).subscribe();
  }
  handleSaveReturn(saveReturn:NewUserResult){
    if(saveReturn.IsError){
      let toastShown:boolean = false;
      if (saveReturn.IsEmailInUse){
        this.toastr.addToastr(new Toastr({Message: "That email is in use, please use another.", Type: 3}));    
        toastShown = true;
      }
      if (!saveReturn.IsEmailValid){
        this.toastr.addToastr(new Toastr({Message: "The email address entered is invalid.", Type: 3}));
        toastShown = true;
      }
      //if (!saveReturn.IsLicenseAvailable){
      //  this.toastr.addToastr(new Toastr({Message: "There are currently no licenses available for your account. Please contact your administrator or contact support.", Type: 3}));
      //  toastShown = true;
      //}
      if (saveReturn.IsUsernameInUse){
        this.toastr.addToastr(new Toastr({Message: "The username entered is already in use. Please try another.", Type: 3}));
        toastShown = true;
      }
      if (!saveReturn.IsUsernameValid){
        this.toastr.addToastr(new Toastr({Message: "The username entered is invalid. Please try another.", Type: 3}));
        toastShown = true;
      }
      switch (saveReturn.ErrorCode){
        case "SUP001":
          this.toastr.addToastr(new Toastr({Message: "You have invalid permissions to update this profile.", Type: 3}));
          toastShown = true;
          break;
        case "SUP002":
          this.toastr.addToastr(new Toastr({Message: "An error occurred saving the profile. Please try again later.", Type: 3}))    
          toastShown = true;
          break;             
      }
      if (!toastShown){
        this.toastr.addToastr(new Toastr({Message: "An error occurred saving the profile. Please try again later.", Type: 3}))
      }
    }else{
      this.originalUser = new SaveUser(saveReturn.UserDetails);
      this.toastr.addToastr(new Toastr({Message: "Changes saved.", Type: 2}))
    }
  }
     
  goBack(){
    this.router.navigateByUrl("/settings");
  }
}
