import { audit } from "rxjs";
import { AuditInfo } from "./audit-info";
import { Question } from "./question";
import { AuditEntryOptions } from "./audit-entry-options";
import { AuditCompletionInfo } from "./audit-completion-info";
import { AuditMetaData } from "./audit-meta-data";
import { BasicUser } from "../basic-user";
import { AuditHistory } from "./AuditResults/audit-history";

export class FlattenedQuestionHolder{
    AuditID:number = -1;
    AuditName:string = '';
    Questions:Question[] = [];
    AuditInfo:AuditInfo = new AuditInfo();
    AuditEntryOptions:AuditEntryOptions = new AuditEntryOptions();
    AuditCompletionInfo:AuditCompletionInfo = new AuditCompletionInfo();
    AuditMetaData:AuditMetaData = new AuditMetaData();
    ActionUsers:BasicUser[] = [];
    HistoricInfo:AuditHistory[] = [];
    constructor(values: Object = {}){
        Object.assign(this, values);   
        if(values.hasOwnProperty('AuditCompletionInfo')){
            this.AuditCompletionInfo = new AuditCompletionInfo((values as FlattenedQuestionHolder)['AuditCompletionInfo'] as AuditCompletionInfo) 
         }          
         if(values.hasOwnProperty('AuditEntryOptions')){
            this.AuditEntryOptions = new AuditEntryOptions((values as FlattenedQuestionHolder)['AuditEntryOptions'] as AuditEntryOptions) 
         }   
         if(values.hasOwnProperty('AuditMetaData')){
            this.AuditMetaData = new AuditMetaData((values as FlattenedQuestionHolder)['AuditMetaData'] as AuditMetaData) 
         }   
         if(values.hasOwnProperty('AuditInfo')){
            this.AuditInfo = new AuditInfo((values as FlattenedQuestionHolder)['AuditInfo'] as AuditInfo) 
         }   
         if(values.hasOwnProperty('Questions')){
            this.Questions = [];
            ((values as FlattenedQuestionHolder)['Questions'] as Question[]).forEach(element => {
                this.Questions.push(new Question(element));
            });
        }
        if(values.hasOwnProperty('ActionUsers')){
            this.ActionUsers = [];
            ((values as FlattenedQuestionHolder)['ActionUsers'] as BasicUser[]).forEach(element => {
                this.ActionUsers.push(new BasicUser(element));
            });
        }
        if(values.hasOwnProperty('HistoricInfo')){
            this.HistoricInfo = [];
            ((values as FlattenedQuestionHolder)['HistoricInfo'] as AuditHistory[]).forEach(element => {
                this.HistoricInfo.push(new AuditHistory(element));
            });
        }
    }
}