import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { AuditCollectionResult } from 'src/app/core-module/models/Audits/audit-collection-result';
import { FlattenedQuestionHolder } from 'src/app/core-module/models/Audits/flattened-question-holder';
import { Toastr } from 'src/app/core-module/models/Utils/toastr';
import { AuditService } from 'src/app/core-module/services/audit.service';
import { ToastrService } from 'src/app/core-module/services/toastr.service';
import { UtilService } from 'src/app/core-module/services/util.service';

@Component({
  selector: 'app-select-audit-edit',
  templateUrl: './select-audit-edit.component.html',
  styleUrls: ['./select-audit-edit.component.scss']
})
export class SelectAuditEditComponent {

  public audits: AuditCollectionResult;
  public pendingDelete: number[] = [];
  public deleting: number[] = [];

  constructor(private auditService: AuditService, private router: Router, public util: UtilService, private toastr:ToastrService) {

  }

  ngOnInit() {
    this.loadPage();
  }

  loadPage() {
    this.auditService.getAudits(false).pipe(
      tap(res => {
        this.audits = res;
        this.orderAudits();
      })
    ).subscribe();
  }

  selectAudit(audit: FlattenedQuestionHolder) {
    this.router.navigateByUrl('settings/manage/edit/audit', { state: { 'Audit': audit, 'Duplicate': false } });
  }
  duplicateAudit(audit: FlattenedQuestionHolder) {
    this.router.navigateByUrl('settings/manage/edit/audit', { state: { 'Audit': audit, 'Duplicate': true } });
  }
  orderAudits() {
    this.audits.Audits = this.audits.Audits.sort((a, b) => a.AuditName.localeCompare(b.AuditName));
  }
  setDelete(auditID: number) {
    if (this.forDeletion(auditID)) {
      var index = this.pendingDelete.findIndex(e => e == auditID);
      this.pendingDelete.splice(index, 1);
    } else {
      this.pendingDelete.push(auditID)
    }
    console.log('setDelete', this.pendingDelete);
  }
  forDeletion(auditID: number) {
    if (this.pendingDelete.findIndex(e => auditID == e) == -1) {
      return false;
    }
    return true;
  }


  deleteAudit(auditID: number) {
    this.deleting.push(auditID);
    this.auditService.deleteAudit(auditID).pipe(
      tap(res => {
        this.resetDeletingStatus(auditID);
        if (res.IsError) {
          this.toastr.addToastr(new Toastr({ Message: 'Unable to remove the audit, please try again later.', Type: 3 }));
          //unable to delete rn
        } else {
          this.toastr.addToastr(new Toastr({ Message: 'Audit successfully removed.', Type: 2 }));
          this.loadPage();
        }
      })
    ).subscribe();
  }
  attemptingDelete(auditID: number) {
    if (this.deleting.findIndex(e => auditID == e) == -1) {
      return false;
    }
    return true;
  }
  resetDeletingStatus(auditID: number) {
    if (this.attemptingDelete(auditID)) {
      var index = this.deleting.findIndex(e => e == auditID);
      this.deleting.splice(index, 1);
    }
  }




  goBack() {
    this.router.navigateByUrl("/settings");
  }
}
