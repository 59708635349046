<div class="container">

    <div class="px-4 mt-2">
        <button class="btn-inverse py-1" (click)="goBack()">
            < Back</button>
    </div>
    <div class="border-solid border-2 border-grey-300 mb-1 mt-3 mx-4"></div>


    <h1 class="lg:px-8 mt-4 p-2">Select Audit</h1>
    <div *ngIf="audits!=undefined">
        <ul class="mx-4 mt-2">
            <li *ngFor="let a of audits.Audits" >

                <div
                    class="border my-2 border-grey-900 rounded-lg p-4 ">
                    <div class="flex justify-between w-full">
                        <div>
                            <h2>{{a.AuditName}}</h2>
                            <p class="pt-4"><span class="text-sm text-grey-900">Audit Type:
                                </span>{{a.AuditInfo.AuditType.Description}}</p>
                            <p><span class="text-sm text-grey-900">Audit Frequency:
                                </span>{{a.AuditInfo.Frequency.Description}}</p>
                            <p class="pt-4"><span class="text-sm text-grey-900">Last Updated:
                                </span>{{util.humanDate(a.AuditInfo.LastUpdated)}}</p>
                        </div>

                        <div class="flex flex-col justify-between items-center">
                            <span class="material-symbols-outlined text-2xl cursor-pointer hover:text-green-400" (click)="selectAudit(a)">edit</span>
                            <span class="material-symbols-outlined text-2xl cursor-pointer hover:font-black" (click)="duplicateAudit(a)">file_copy</span>
                            <span class="material-symbols-outlined text-2xl cursor-pointer hover:text-red-600" (click)="setDelete(a.AuditID)" >delete</span>
                        </div>
                    </div>
                    <div class="my-6 p-3 border-grey-300 border-2 rounded-md mx-4" *ngIf="forDeletion(a.AuditID)">
                        <h4 class="mb-2">Confirm Audit Removal</h4>
                        <p>Once inactive, the audit will be removed from the list of audits that can be completed. </p>
                        <p><strong>NOTE - This is for all users at all locations.</strong></p>
                        <div class="flex my-5">                           
                            <button class="btn-primary btn-negative mr-2" (click)="deleteAudit(a.AuditID)" [disabled]="attemptingDelete(a.AuditID)">Confirm Removal</button>
                            <button class="btn-primary btn-warning " (click)="setDelete(a.AuditID)" >Cancel</button>
                        </div>            
                    </div>
                </div>
            </li>
        </ul>
    </div>

</div>