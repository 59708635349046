export class LocationAudit {
    LocationID:number = -1;
    AuditID:number = -1;
    AuditName:string = "";
    AssignedToLocation:boolean = false;
    AuditType:string = "";
    AuditTypeID:number = -1;
    constructor(values: Object = {}) {
    Object.assign(this, values);
    }
}