<div class="h-screen w-screen flex py-8 md:p-0 md:items-center justify-center">
  <div class="bg-offwhite-100 rounded-xl center w-11/12 md:w-3/4 shadow-[0_8px_30px_rgb(0,0,0,0.12)]">
    <div class="flex flex-col h-full">
      <div class="flex flex-row h-full">
        <div class="flex flex-col jh-full w-full md:w-3/5 p-4 pr-2">
          <img src="/assets/logo.png" class="md:hidden max-h-24 mr-4 mt-4 object-scale-down flex justify-self-start"
            *ngIf="!isBluebird" />
          <img src="/assets/bluebirdlogo.png"
            class="md:hidden max-h-24 mr-4 mt-4 object-scale-down flex justify-self-start" *ngIf="isBluebird" />
          <div class="flex flex-col justify-center" *ngIf="!showForgotPassword">
            <h1 class="p-4 md:p-8 md:pt-12 pt-16">Login</h1>

            <div class="md:px-8 px-4 pb-4">
              <label class="block pt-4">
                <span class="">Username</span>
                <input type="text" [(ngModel)]="username" class="text-box" #usernameElement />
              </label>
              <label class="block pt-6">
                <span class="">Password</span>
                <input type="password" [(ngModel)]="password" class="text-box" (keypress)="checkLogin($event)" />
              </label>
              <div class="pt-10">
                <button class="btn-primary-full" (click)="login()" [class.disabled]="!signInAllowed"
                  [disabled]="!signInAllowed">
                  <span *ngIf="signInAllowed">Login</span>
                  <span *ngIf="!signInAllowed">Signing In...</span>
                </button>
              </div>
              <span class="form-error" *ngIf="errorText != ''">{{
                errorText
                }}</span>
              <div class="pt-8">
                <a (click)="showForgot()" class="text-sm block underline hover:cursor-pointer">Forgotten your
                  Password?</a>
              </div>
            </div>
          </div>
          <div class="flex flex-col justify-center" *ngIf="showForgotPassword">
            <h1 class="p-4 md:p-8 md:pt-12 pt-16">Password Reset</h1>
            <div class="md:px-8 px-4">
              <a (click)="showLogin()" class="text-sm block underline hover:cursor-pointer">
                < Back to Login</a>
            </div>
            <div class="md:px-8 px-4 pt-6">
              <label class="block pt-4">
                <span class="">Email</span>
                <input type="email" [(ngModel)]="email" class="text-box" />
              </label>
              <div class="pt-10">
                <button class="btn-primary-full" (click)="resetPassword()" [class.disabled]="email.length == 0"
                  [disabled]="email.length == 0 || resetClicked">
                  <span *ngIf="!resetClicked">Reset Password</span>
                  <span *ngIf="resetClicked">Sending Reset Email...</span>
                </button>
                <span class="pt-4" *ngIf="resetEmailSent">An email will be sent to you if an account has been
                  found.</span>
                <span class="pt-4 form-error" *ngIf="resetErrorMessage">An error occurred resetting your password,
                  please try again later.</span>
              </div>
            </div>


          </div>

        </div>
        <div class="flex-col justify-between w-2/5 p-4 pl-2 pb-0 bg-offwhite-500 rounded-xl hidden md:flex"
          *ngIf="!isBluebird">
          <img src="/assets/logo.png" class="p-4 mt-4 center max-h-28 object-scale-down" />
          <img src="/assets/tabletuser.png" class="object-bottom" *ngIf="!isBluebird" />
        </div>
        <div class="flex-col justify-between w-2/5 pb-0 bg-offwhite-500 rounded-xl hidden md:flex " *ngIf="isBluebird">
          <img src="/assets/bluebirdlogo.png" class="p-4 mt-4 center max-h-28 object-scale-down" />
          <div class="bg-[url('/assets/bluebirdswirlsmall.png')] bg-repeat h-full">

          </div>
        </div>
      </div>
    </div>
  </div>
</div>