import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { AuditCollectionResult } from 'src/app/core-module/models/Audits/audit-collection-result';
import { AuditService } from 'src/app/core-module/services/audit.service';
import { UtilService } from 'src/app/core-module/services/util.service';

@Component({
  selector: 'app-start-audit',
  templateUrl: './start-audit.component.html',
  styleUrls: ['./start-audit.component.scss']
})
export class StartAuditComponent implements OnInit {

  canStart: boolean = false;
  a = new AuditCollectionResult();
  selectedAudit: any;

  constructor(private audit: AuditService, private router: Router,private util:UtilService) { }

  ngOnInit() {
    this.audit.getAudits().pipe(
      tap(res => {
        this.a = res;
        this.selectedAudit = this.a.Audits[0];
        this.orderAudits();
        this.canStart = true;
      })
    ).subscribe();
  }

  startAudit() {
    this.canStart = false;
    this.router.navigateByUrl('/selectlocation', { state: { "audit": this.selectedAudit } });
  }
  orderAudits() {
    console.log(this.a);
    this.a.Audits = this.a.Audits.sort((a, b) => {
      if (a.AuditName > b.AuditName) {
        return 1;
      }

      if (a.AuditName < b.AuditName) {
        return -1;
      }

      return 0;
    })
  }
  getHumanDate(dateEntry:Date):string{
    return this.util.humanDate(dateEntry);
  }


}
