import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { ComplianceAction } from 'src/app/core-module/models/Audits/compliance-action';
import { Question } from 'src/app/core-module/models/Audits/question';
import { StandardQuestionEntry } from 'src/app/core-module/models/Audits/standard-question-entry';
import { validDateGreaterThan } from 'src/app/core-module/models/Utils/Validators/validDateGreaterThan';
import { validForbiddenValues } from 'src/app/core-module/models/Utils/Validators/validForbiddenValues';
import { validRequiredValues } from 'src/app/core-module/models/Utils/Validators/validRequiredValue';
import { BasicUser } from 'src/app/core-module/models/basic-user';
import { UserService } from 'src/app/core-module/services/user.service';

@Component({
  selector: 'app-question-action',
  templateUrl: './question-action.component.html',
  styleUrls: ['./question-action.component.scss'],
})
export class QuestionActionComponent {

   eventsSubject: Subject<void> = new Subject<void>();

  constructor(private formBuilder: FormBuilder, private userService: UserService) { }

  @Input() currentQuestion: Question = new Question();
  @Input() actionUsers:BasicUser[] = [];
  @Output() currentQuestionChange = new EventEmitter<Question>();

  public currentAction: ComplianceAction = new ComplianceAction();
  public otherAction: boolean = false;
  public _users: BasicUser[] = [];
  public av: actionValidator = new actionValidator();
  public standardActions: ComplianceAction[];
  public isEdit: boolean = false;

  public actionHolder: StandardQuestionEntry = new StandardQuestionEntry();
  //Possible remove
  public isSubmitCheck: boolean = false;
  public actionValid:boolean = true;

  ngOnInit() {
    this._users = this.actionUsers.sort((a, b) => { if (a.UserTypeID == b.UserTypeID) { return a.DisplayName.localeCompare(b.DisplayName) } else { return b.UserTypeID - a.UserTypeID } });
    this.setUserDDL();
    this.av.actionNote = new FormControl(this.currentAction.ActionNote, [Validators.required, Validators.minLength(5)]);
    this.av.actionDate = new FormControl(this.currentAction.ActionDeadline, [Validators.required, validDateGreaterThan(true)]);
    this.av.actionUser = new FormControl(this.currentAction.ActionOwner.UserID, [Validators.required, validForbiddenValues(/-1/i)]);
    this.av.actionID = new FormControl(this.currentAction.ActionID, [Validators.required, validForbiddenValues(/-1/i)]);
    this.av.actionOther = new FormControl(this.otherAction, [validRequiredValues(/True/i)]);
  }
  ngOnChanges() {
    this.resetDDL();
  }
  resetDDL() {
    this.setActionDDL();
    this.setUserDDL();
  }
  setActionDDL() {
    if(this.currentQuestion.StandardActions.some(e => {e.StandardQuestionEntryID != -1})){
      this.otherAction = false;
    }else{
      this.otherAction = true;
    }
    if (this.currentQuestion.StandardActions.some(e => { e.IsDefault })) {
      this.currentAction.StandardAction = this.currentQuestion.StandardActions.find(f => { f.IsDefault })!;
    } else {
      if (!this.currentQuestion.StandardActions.some(e => e.StandardQuestionEntryID == -1)) {
        var newAction = new StandardQuestionEntry({ 'StandardQuestionEntryID': -1, 'StandardEntryText': 'Please Select...' });
        this.currentQuestion.StandardActions.unshift(newAction)
      }
      this.currentAction.StandardAction = this.currentQuestion.StandardActions.find(e => { return e.StandardQuestionEntryID == -1 })!;
    }
  }
  setUserDDL() {
    if(this._users.some(e => e.UserID == -1)){
      var IX = this._users.findIndex(e=>e.UserID == -1);
      /*console.log('users:',this._users,'IX',IX);
      console.log('slice',this._users.slice(IX,1));*/
      this._users.splice(IX,1);
    }
    var newUser = new BasicUser({ 'UserID': -1, 'DisplayName': 'Please Select...', 'Seq': 0, 'UserTypeID': 0 });
    this._users.unshift(newUser);
    this.currentAction.ActionOwner = this._users.find(e => { return e.UserID == -1 })!;
  }
  resetActionDisplay() {
    this.currentAction = new ComplianceAction();
    this.otherAction = false;
    this.isEdit = false;
    this.actionValid = true;
    this.resetDDL();
  }
  addAction() {
    if(this.validateAction()){
      this.currentQuestion.CompletionInfo.Actions.push(this.currentAction);
      this.resetActionDisplay();
      this.currentQuestionChange.emit(this.currentQuestion);
    }
  }
  validateAction(){
    if(this.currentAction.ActionOwner.UserID == -1){
      this.actionValid = false;
      return false;
    }else{
      this.actionValid = true;
      return true;
    }
  }
  saveAction() {
    if(this.validateAction()){
      this.currentQuestion.CompletionInfo.Actions[this.returnEditingIndex()] = this.currentAction;
      this.isEdit = false;
      this.resetActionDisplay();
      this.currentQuestionChange.emit(this.currentQuestion);
    }

  }
  editAction(CA: ComplianceAction) {
    this.currentAction = new ComplianceAction(CA);
    this.otherAction = (CA.StandardAction.StandardQuestionEntryID == -1);
    this.actionValid = true;
    this.isEdit = true;
  }
  actionComparison(a: StandardQuestionEntry, b: StandardQuestionEntry): boolean {
    if(a==undefined || b==undefined || a==null || b==null){
      return false;
    }
    return a.StandardQuestionEntryID == b.StandardQuestionEntryID;
  }
  userComparison(a: BasicUser, b: BasicUser): boolean {
    if(a==undefined || b==undefined || a==null || b==null){
      return false;
    }
    return (a.UserID == b.UserID && a.UserTypeID == b.UserTypeID)
  }
  deleteAction() {
    if (this.isEdit) {
      var index: number = this.returnEditingIndex()
      if (index != -1) {
        this.currentQuestion.CompletionInfo.Actions.splice(index, 1);
      }      
    }
    this.resetActionDisplay();
    this.currentQuestionChange.emit(this.currentQuestion);
  }
  returnEditingIndex(): number {
    return this.currentQuestion.CompletionInfo.Actions.findIndex(e => {
      return e.Editing
    });
  }
  updateFormValidation() {

    this.av.actionDate.setValue(this.currentAction.ActionDeadline);
    this.av.actionID.setValue(this.currentAction.ActionID);
    this.av.actionNote.setValue(this.currentAction.ActionNote);
    this.av.actionUser.setValue(this.currentAction.ActionOwner.UserID);
    this.av.actionOther.setValue(this.otherAction);
  }
}

class actionValidator {
  actionNote: FormControl;
  actionID: FormControl;
  actionDate: FormControl;
  actionUser: FormControl;
  actionOther: FormControl;
  isValid() {
    if (this.actionOther.valid) {
      return this.actionNote.valid && this.actionDate.valid && this.actionUser.valid;
    } else {
      return this.actionDate.valid && this.actionUser.valid && this.actionID.valid;
    }
  }
}

