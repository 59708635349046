<div class="container p-4">
    <div class="px-4 mt-2">
        <button class="btn-inverse py-1" (click)="goBack()">< Back</button>
    </div>
    <div class="border-solid border-2 border-grey-500 mb-1 mt-3 mx-4"></div>
    <h1 class="p-4" *ngIf="!isGroup">New User</h1>
    <h1 class="p-4" *ngIf="isGroup">New Group</h1>

    <div *ngIf="noLicense" class="mx-8 mt-8 border-solid border-grey-900 border-2 rounded-md p-4 inline-block">
        <p class="text-green-700 text-lg" *ngIf="noLicense">No available user licenses, please contact your account
            manager.</p>
    </div>

    <div *ngIf="!noLicense" class="mx-4 mt-6">

        <div class="xl:w-3/5 lg:w-4/5">
            <div class="mb-4" *ngIf="!isGroup">
                <label class="mr-2">Username</label>
                <input type="text" class="text-box" [(ngModel)]="newUser.Username" (ngModelChange)="resetFlags()" />
                <p *ngIf="invalidUserName">Username is invalid</p>
                <p *ngIf="isUsernameInUse">Username must be unique, please select a different username</p>
            </div>

            <div class="mb-4">
                <label class="mr-2" *ngIf="!isGroup">Display Name</label>
                <label class="mr-2" *ngIf="isGroup">Group Name</label>
                <input type="text" class="text-box" [(ngModel)]="newUser.DisplayName" (ngModelChange)="resetFlags()" />
                <p *ngIf="invalidDisplayName">Display Name is not valid</p>
            </div>
            <div class="mb-8" *ngIf="!isGroup">
                <label class="mr-2">Email</label>
                <input type="email" class="text-box" [(ngModel)]="newUser.Email" (ngModelChange)="resetFlags()" />
                <p *ngIf="invalidEmail">Email is invalid</p>
                <p *ngIf="emailUsed">Email is already assigned to an account</p>
            </div>
            <div class="mb-6 py-4 px-2 border-solid border-y-2 border-grey-300">
                <div class="flex justify-between">
                    <label class="mr-2">Daily Reminders</label>
                    <app-toggle-switch [(checked)]="newUser.DailyReminders"></app-toggle-switch>
                </div>
                <div class="flex justify-between mt-4">
                    <label class="mr-2">Weekly Summary</label>
                    <app-toggle-switch [(checked)]="newUser.WeeklySummary"></app-toggle-switch>
                </div>
            </div>

        </div>
        <div *ngIf="!noLicense">
            <button class="btn-primary mr-3 " (click)="validateUser()" [disabled]="isSaving">
                <span *ngIf="!isSaving" class="">Save </span>
                <span *ngIf="isSaving"  class="">Saving </span> 
                <span *ngIf="!isGroup">User</span> 
                <span *ngIf="isGroup">Group</span>
            </button>
            <button [disabled]="isSaving" (click)="resetUser()" class="btn-warning">Reset</button>
        </div>
    </div>
</div>