<div class="container p-4">
    <div class="px-4 mt-2">
        <button class="btn-inverse py-1" (click)="goBack()">< Back</button>
    </div>
    <div class="border-solid border-2 border-grey-500 mb-1 mt-3 mx-4"></div>


    <h1 class="p-4">Manage Users</h1>
    <div class="mt-3 lg:mt-8 border-solid mx-4 xl:ml-8 ">
        <table class="lg:block hidden my-4 w-full table-auto xl:w-3/5" *ngIf="!editing">
            <thead>
                <tr class="pb-3">
                    <th class="text-left">Display Name</th>
                    <th class="text-left">Email Address</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr  *ngFor="let u of mobileList" class="border-b-2 border-grey-100" [ngClass]="{'hidden':u.Disabled}">
                    <td class="px-4">
                        {{u.DisplayName}}
                    </td>
                    <td >
                        {{u.Email}}
                    </td>
                    <td class="p-2 ml-2">
                        <button class="btn-primary py-1" (click)="selectUser(u)">Update</button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="lg:hidden" *ngIf="!editing">
            <select class="text-box" [(ngModel)]="currentUser" (change)="selectMobileUser()">
                <option *ngFor="let u of mobileList" [ngValue]="u" [disabled]="u.Disabled">{{u.DisplayName}}</option>
            </select>
        </div>
        <div *ngIf="editing">
            <button (click)="switchEditing()" class="btn-inverse">
                &#60; back to users</button>
            <div class="xl:w-3/5 lg:w-4/5 mt-5">
                <div class="mb-4" *ngIf="!isGroup">
                    <div *ngIf="currentUser.HasLoggedIn" class="my-4 p-4 border-solid border-2 rounded-lg border-grey-300">
                        <p class="text-lg font-bold">Active Account</p>
                        <p>Once an account has successfully logged in the account details can only be updated by the user or by contacting our support team. The account can still be made inactive below.</p>
                        <a href="mailto:care@careaudits.co.uk" class="text-green-700 ">Contact Support</a>
                        
                    </div>
                    <label class="mr-2">Username</label>
                    <input type="text" [disabled]="currentUser.HasLoggedIn" [ngClass]="{'text-grey-900':currentUser.HasLoggedIn}" class="text-box" [(ngModel)]="currentUser.Username"
                        (ngModelChange)="resetFlags()" />
                    <p *ngIf="invalidUserName">Username is invalid</p>
                    <p *ngIf="isUsernameInUse">Username must be unique, please select a different username</p>
                </div>

                <div class="mb-4">
                    <label class="mr-2" *ngIf="!isGroup">Display Name</label>
                    <label class="mr-2" *ngIf="isGroup">Group Name</label>
                    <input type="text" class="text-box" [disabled]="currentUser.HasLoggedIn && !isGroup"  [ngClass]="{'text-grey-900':currentUser.HasLoggedIn && !isGroup, 'error': invalidDisplayName}"  [(ngModel)]="currentUser.DisplayName"
                        (ngModelChange)="resetFlags()" />
                    <p *ngIf="invalidDisplayName" class="form-error">Display Name is not valid</p>
                </div>
                <div class="mb-8" *ngIf="!isGroup">
                    <label class="mr-2">Email</label>
                    <input type="email" class="text-box" [disabled]="currentUser.HasLoggedIn"  [ngClass]="{'text-grey-900':currentUser.HasLoggedIn, 'error':invalidEmail || emailUsed}" [(ngModel)]="currentUser.Email"
                        (ngModelChange)="resetFlags()" />
                    <p *ngIf="invalidEmail" class="form-error">Email is invalid</p>
                    <p *ngIf="emailUsed" class="form-error">Email is already assigned to an account</p>
                </div>
                <div class="flex">
                    <label class="mr-4">Active</label>
                    <app-toggle-switch [(checked)]="currentUser.Active"></app-toggle-switch>
                </div>
                <button class="btn-primary mt-6" (click)="validate()" [disabled]="saving">
                    <span *ngIf="!saving">Save</span>
                    <span *ngIf="saving">Saving...</span>
                </button>
            </div>
        </div>
    </div>



</div>