import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { UserAudit } from 'src/app/core-module/models/UserManagement/user-audit';
import { Toastr } from 'src/app/core-module/models/Utils/toastr';
import { ToastrService } from 'src/app/core-module/services/toastr.service';
import { UserManagementService } from 'src/app/core-module/services/usermanagement.service';

@Component({
  selector: 'app-user-audits',
  templateUrl: './user-audits.component.html',
  styleUrl: './user-audits.component.scss'
})
export class UserAuditsComponent {

  public unsavedChanges: boolean = false;
  public isGroup: boolean = false;
  public isSaving: boolean = false;

  public initialData: UserAudit[] = [];

  public users: UserAudit[] = [];
  public selectedUser: UserAudit = new UserAudit();

  public allPermissions: boolean = false;

  constructor(private UMS: UserManagementService, private router: Router, private toastr:ToastrService) {
    if (this.router.url == '/settings/users/groupaudits') {
      this.isGroup = true;
    }
    this.getData();
  }
  getData() {
    this.UMS.getUserAudits((this.router.url == '/settings/users/groupaudits')).pipe(tap((a) => { this.initialData = a; this.setData();})).subscribe();
  }
  setData() {
    this.users = [];
    this.users.push(new UserAudit({ UserID: -1, DisplayName: 'Please select...' }))
    this.selectedUser = this.users[0];
    this.initialData.forEach(e => {
      this.users.push(new UserAudit(e));
    })
  }
  loadUser() {
    this.checkAllPermissions();
  }
  updateAllPermissions(){
    this.selectedUser.AuditPermissions.forEach(x=>{
      x.HasPermission = this.allPermissions;
    })
  }
  checkAllPermissions(){
    var permissionCheck = true;
    this.selectedUser.AuditPermissions.forEach(x => {
      if (!x.HasPermission) {
        permissionCheck = false;
      }
    });
    this.allPermissions = permissionCheck;
  }
  saveChanges() {
    this.isSaving = true;
    this.UMS.saveUserAuditChange(this.selectedUser).pipe(
      tap((a) => { 
        this.getData(); 
        this.isSaving = false;
        this.toastr.addToastr(new Toastr({Message: "Changes saved.", Type: 2}))
      })
    ).subscribe();
  }
  resetPage() {
    this.setData();
  }
  goBack(){
    this.router.navigateByUrl("/settings");
  }
}
