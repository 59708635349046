
<div
  class="w-full p-3 mt-1 bg-standardwhite rounded-md flex justify-between flex-col border-[1px] border-grey-300 border-solid">
  <div class="flex flex-col lg:flex-row w-full mr-5">
    <div class="w-full lg:w-2/5 lg:min-w-2/5"
      [ngClass]="{'border-red-100 border-2 border-solid':av.actionNote.invalid && isSubmitCheck}">
      <h5>Action Required</h5>
      <label for="otherAction">Other Action</label>
      <app-toggle-switch [(checked)]="otherAction"></app-toggle-switch>
      <!--<app-drop-down name="actionID" [events]="eventsSubject" [auditTemplateDDL]="_standardActionDDL" (SelectedOptionFullEmit)="updateDefaultAction($event)"></app-drop-down>-->
      <select class="text-box max-w-xl" name="StandardActions" 
        [ngClass]="{'hidden':otherAction}" [(ngModel)]="currentAction.StandardAction" [compareWith]="actionComparison">
        <option *ngFor="let a of currentQuestion.StandardActions" [ngValue]="a"
          [disabled]="a.StandardQuestionEntryID == -1">{{a.StandardEntryText}}</option>
      </select>
      <textarea name="actionNote" class="text-box mt-3" rows="3" [(ngModel)]="currentAction.ActionNote"
        (keyup)="updateFormValidation()" [ngClass]="{'hidden':!otherAction}"></textarea>
      <div class="mt-5 w-full flex flex-col"
        [ngClass]="{'border-red-100 border-2 border-solid':av.actionUser.invalid && isSubmitCheck}">
        <h5>Action Owner</h5>
        <select class="text-box max-w-xl" name="ActionOwner" [(ngModel)]="currentAction.ActionOwner"
          [ngClass]="{'font-bold':currentAction.ActionOwner.UserTypeID==2, 'font-normal':currentAction.ActionOwner.UserTypeID!=2}" [compareWith]="userComparison" (change)="validateAction()">
          <option *ngFor="let u of _users" [ngClass]="{'font-bold':u.UserTypeID==2, 'font-normal':u.UserTypeID!=2}"
            [ngValue]="u" [disabled]="u.UserID == -1">{{u.DisplayName}}</option>
        </select>
      </div>
      <div class="flex-col mt-5"
        [ngClass]="{'border-red-100 border-2 border-solid':av.actionDate.invalid && isSubmitCheck}">
        <h5>Select Timeframe</h5>
        <label>
          Date:
          <input [owlDateTime]="dta" class="text-box " name="ActionDeadline" [(ngModel)]="currentAction.ActionDeadline"
            [owlDateTimeTrigger]="dta" />
          <owl-date-time [pickerType]="'calendar'" #dta></owl-date-time>
        </label>
        <p class="text-red-600 my-2" *ngIf="!actionValid">Please select an action owner.</p>
      </div>
      <div class="flex mt-2">
        <button *ngIf="!isEdit" class="btn-primary px-2 py-1 mr-2" (click)="addAction()">Add</button>
        <button *ngIf="!isEdit" class="btn-primary px-2 py-1" (click)="deleteAction()">Clear</button>
        <button *ngIf="isEdit" class="btn-primary px-2 py-1 mr-2" (click)="saveAction()">Save</button>
        <button *ngIf="isEdit" class="btn-primary px-2 py-1 mr-2" (click)="deleteAction()">Delete</button>        
        <button *ngIf="isEdit" class="btn-primary px-2 py-1" (click)="resetActionDisplay()">Undo</button>
      </div>

    </div>
    <div class="flex-col flex mt-5 lg:mt-0 ml-0 lg:ml-4 flex-grow w-3/5">
      <h5>Actions</h5>
      <app-question-action-useraction [(actionList)]="currentQuestion.CompletionInfo.Actions" [editing]="isEdit"
        (actionChange)="editAction($event)" class="w-full"></app-question-action-useraction>
    </div>
  </div>
</div>