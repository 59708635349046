import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { BasicUser } from 'src/app/core-module/models/basic-user';
import { GroupsHolder } from 'src/app/core-module/models/UserManagement/groups-holder';
import { UserGroup } from 'src/app/core-module/models/UserManagement/user-group';
import { Toastr } from 'src/app/core-module/models/Utils/toastr';
import { ToastrService } from 'src/app/core-module/services/toastr.service';
import { UserManagementService } from 'src/app/core-module/services/usermanagement.service';

@Component({
  selector: 'app-group-management',
  templateUrl: './group-management.component.html',
  styleUrl: './group-management.component.scss'
})
export class GroupManagementComponent {

  groups: GroupsHolder = new GroupsHolder;
  selectedGroup: UserGroup = new UserGroup();
  loadedGroup: UserGroup = new UserGroup();
  groupSelected: boolean = false;
  noGroups:boolean = false;
  saving:boolean = false;

  completeUserList: BasicUser[] = [];


  constructor(private UMS: UserManagementService, public router: Router, private toastr:ToastrService) {
    UMS.getGroupList().pipe(tap((a) => { this.updateGroups(a)})).subscribe();
  }
  addPleaseSelect() {    
    this.groups.Groups.unshift(new UserGroup({ DisplayName: "Please select...", UserID: -1 }));
    this.selectedGroup = this.groups.Groups[0];
  }
  combineUsers() {
    this.completeUserList = [];
    this.groups.PossibleUsers.forEach(x=>{
      this.completeUserList.push(new BasicUser(x));
    });
    this.loadedGroup.Users.forEach(x => {
      x.Selected = false;
      if (this.completeUserList.findIndex(y => y.UserID == x.UserID) == -1) {
        //add user
        x.Selected = true;
        this.completeUserList.push(x);
      } else {
        this.completeUserList.find(y => y.UserID == x.UserID)!.Selected = true;
      }
    })
    this.completeUserList.sort((a, b) => a.DisplayName < b.DisplayName ? -1 : a.DisplayName > b.DisplayName ? 1 : 0);
  }
  loadGroup() {
    if (this.selectedGroup.UserID != -1) {
      this.groupSelected = true;
      this.loadedGroup = this.selectedGroup;
      this.combineUsers();
    }

  }
  saveGroup() {
    this.saving = true;
    var returnHolder = this.loadedGroup;
    returnHolder.Users = this.completeUserList.filter(x => x.Selected);
    this.UMS.saveGroupUpdate(returnHolder).pipe(
      tap((a) => { 
        this.updateGroups(a);
        this.toastr.addToastr(new Toastr({Message: "Changes saved.", Type: 2}))   
        this.saving = false;
      })
    ).subscribe()
  }
  updateGroups(groups:GroupsHolder){
    if(groups.Groups.length == 0){
      this.noGroups = true;
    }else{
      this.noGroups = false;
      this.groups = groups; this.addPleaseSelect();
    }
    
  }
  clearCurrentGroup(){
    this.groupSelected = false;
  }
  resetGroup(){
    this.loadGroup();
  }
  goBack(){
    this.router.navigateByUrl("/settings");
  }

}
