import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { AuditLocation } from '../models/Locations/audit-location';
import { BasicResult } from '../models/basic-result';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LocationManagementService {

  
  constructor(private httpClient: HttpClient) {
  }
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    observe: 'response' as 'response',
    responseType: 'json' as const,
    withCredentials: true,
  };
  submitLocationEdit(updatedLocation:AuditLocation): Observable<BasicResult> {
    return this.httpClient.post<BasicResult>(`${environment.apiUrl}locations/savelocationedit`,updatedLocation, this.httpOptions).pipe(
      map(res => {
        return new BasicResult(res.body!);
      })
    );
  }
  getAllLocationsToAssignAudit(): Observable<AuditLocation[]> {
    return this.httpClient.get<AuditLocation[]>(`${environment.apiUrl}locations/getlocationaudits`, this.httpOptions).pipe(
      map(res => {
        return res.body!;
      })
    );
  }
  
}
