<div class="container lg:p-4 p-2">
    <div class="px-4 mt-2">
        <button class="btn-inverse py-1" (click)="goBack()">< Back</button>
    </div>
    <div class="mx-2 mt-4 border-grey-500 border-b-2 border-solid"></div>
    <h1 class="p-4 lg:mb-8 mb-2">Profile</h1>
    <div class="mx-4 ">
        <p class="text-lg text-green-700 mb-4  font-bold">General Details</p>
        <div class="mb-4">
            <app-text-box-entry [(isEditing)]="isEditingName" (isEditingChange)="updateEditing()"
                [(value)]="editingUser.DisplayName"></app-text-box-entry>
        </div>
        <div class="mb-4">
            <app-text-box-entry [(isEditing)]="isEditingEmail" (isEditingChange)="updateEditing()"
                [(value)]="editingUser.Email"></app-text-box-entry>
        </div>

        <div class="xl:w-2/5 lg:w-3/5">
            <div class="mt-4 mb-6 mx-2 border-grey-500 border-b-2 border-solid"></div>
            <p class="text-lg text-green-700 mb-4 font-bold">Email Preferences</p>
            <div class="mx-2">
                <div class="flex mb-4 justify-between items-center">
                    <label>Daily Reminders</label><app-toggle-switch
                        [(checked)]="editingUser.DailyReminders"></app-toggle-switch>
                </div>
                <div class="flex  justify-between items-center">
                    <label>Weekly Summary</label><app-toggle-switch
                        [(checked)]="editingUser.WeeklySummary"></app-toggle-switch>
                </div>
            </div>
            <div class="flex flex-col mt-8" >
                <button class="btn-primary mb-4" [disabled]="isEditing || isSaving" (click)="validateUser()">
                    <span *ngIf="!isSaving">Save Changes</span>
                    <span *ngIf="isSaving">Saving Changes...</span>
                </button>
                <button class="btn-warning" (click)="reset()" [disabled]="isEditing || isSaving">Revert Changes</button>
            </div>
        </div>
    </div>


</div>