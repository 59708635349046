import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { BasicResult } from 'src/app/core-module/models/basic-result';
import { AuditLocation } from 'src/app/core-module/models/Locations/audit-location';
import { UserLocation } from 'src/app/core-module/models/UserManagement/user-location';
import { UserLocationsHolder } from 'src/app/core-module/models/UserManagement/user-locations-holder';
import { Toastr } from 'src/app/core-module/models/Utils/toastr';
import { LocationManagementService } from 'src/app/core-module/services/location-management.service';
import { ToastrService } from 'src/app/core-module/services/toastr.service';
import { UserManagementService } from 'src/app/core-module/services/usermanagement.service';

@Component({
  selector: 'app-edit-location',
  templateUrl: './edit-location.component.html',
  styleUrl: './edit-location.component.scss'
})
export class EditLocationComponent {
  constructor(private UMS: UserManagementService, private LMS: LocationManagementService, private router: Router, private toastr:ToastrService) {
    this.getLocations();


  }
  public showLocation: boolean = true;
  public topLevel: boolean = true;
  public locations: AuditLocation[] = [];
  public selectedLocations: AuditLocation[] = [];
  public locationsReady: boolean = false;
  public possibleLocations: AuditLocation[] = [];
  public isLocationSelected: boolean = false;
  public selectedLocation: AuditLocation = new AuditLocation();
  public loadedLocation: AuditLocation = new AuditLocation();
  public originalData: AuditLocation[] = [];
  public minLevelID: number;
  public minSeq: number;

  public editingParent: boolean = false;
  public possibleParents: AuditLocation[] = [];
  public displayParents: AuditLocation[] = [];
  public parentListAvailable: boolean = false;
  public selectedParent: AuditLocation = new AuditLocation();
  public minParentLevelID: number = -1;
  public topParentLevel: boolean = false;
  public hasAvailableParent: boolean = false;
  public hasEditParent: boolean = false;

  public saving: boolean = false;

  public isNewLocation: boolean = false;

  getLocations() {
    this.UMS.getEditableLocations().pipe(tap(a => { this.originalData = a, this.setMinLevel(), this.initialList(); this.setPageType(); })).subscribe()
  }

  resetLocations() { this.initialList() };
  selectLocation(selectedLocation: AuditLocation) {
    if (selectedLocation.HasPermission == true) {
      this.selectedLocation = selectedLocation;
      this.isLocationSelected = true;
      this.loadedLocation = this.selectedLocation;
      this.resetParentLocation();
      this.createPossibleParentList();
      this.validateParent();

    }
  }
  validateParent() {

    if (this.originalData.some(x => x.LocationID == this.selectedLocation.ParentLocationID)) {
      this.hasAvailableParent = true;
    } else {
      this.hasAvailableParent = false;
    }


  }
  setPageType() {
    if (this.router.url == '/settings/locations/newlocation') {
      this.isNewLocation = true;
      this.isLocationSelected = true;
      this.createPossibleParentList();

      if (this.possibleParents.length > 0) {
        this.hasEditParent = true;
      }
    } else {
      this.isNewLocation = false;
    }
  }

  setMinLevel() {
    this.minSeq = this.originalData[0].LocationLevelSeq;
    this.minLevelID = this.originalData[0].LocationLevelID;
    this.originalData.forEach(e => {
      if (e.LocationLevelSeq < this.minSeq) {
        this.minSeq = e.LocationLevelSeq;
        this.minLevelID = e.LocationLevelID;
      }
    })
  }
  initialList() {
    this.possibleLocations = [];
    this.topLevel = true;

    //this.possibleLocations.push(new UserLocation({HasPermission:false,LocationName:"Please select...",LocationID:-1}))
    //this.selectedLocation = this.possibleLocations[0];
    this.originalData.forEach(e => {
      if (e.LocationLevelID == this.minLevelID) {
        this.possibleLocations.push(new AuditLocation(e));
      }
      this.locationsReady = true;
    })
  }
  saveChanges() {
    if (this.validateLocation()) {
      this.LMS.submitLocationEdit(this.selectedLocation).pipe(tap((a) => { this.saveComplete(a) })).subscribe();
    }else{

    }
  }
  validateLocation(): boolean {
    if (this.selectedLocation.LocationName.length == 0) {
      return false;
    }
    return true;
  }
  saveComplete(br: BasicResult) {
    this.saving = false;
    if (br.IsError == false) {
      this.getLocations();
      this.isLocationSelected = false;
      this.selectedLocation = new AuditLocation();
      this.toastr.addToastr(new Toastr({Message: "Changes saved.", Type: 2}))
    } else {
      this.toastr.addToastr(new Toastr({Message: "An error occurred editing the location. Please try again later.", Type: 3}))
    }
  
  }
  confirmParentLocation() {

    this.selectedLocation.ParentLocationID = this.selectedParent.LocationID;
    this.hasAvailableParent = true;
    this.switchParentEdit();
  }
  locationHasChildren(location: AuditLocation) {
    return this.originalData.findIndex(x => x.ParentLocationID == location.LocationID) != -1;
  };
  parentLocationHasChildren(location: AuditLocation) {
    return this.possibleParents.findIndex(x => x.ParentLocationID == location.LocationID) != -1;
  }

  buildLocationDDL(parentID: number) {
    this.possibleLocations = [];
    if (parentID != -1) {
      if (this.originalData.find(e => parentID == e.LocationID)!.LocationLevelID == this.minLevelID) {
        this.topLevel == true;
      } else {
        this.topLevel = false;
      }

    } else {
      this.topLevel = true;
    }
    //this.possibleLocations.push(new UserLocation({HasPermission:false,LocationName:"Please select...",LocationID:-1}))
    //this.selectedLocation = this.possibleLocations[0];
    this.originalData.forEach(e => {
      if (e.ParentLocationID == parentID) {
        this.possibleLocations.push(new AuditLocation(e));
      }

      this.locationsReady = true;
    })
  };
  changeLocationActive() {
    this.selectedLocation.Active = !this.selectedLocation.Active;
  }
  changeLocation() {
    this.selectedLocation = new AuditLocation();
    this.resetLocations()
    this.isLocationSelected = false;
  }
  locationChanged(newLocationName: string) {
    this.selectedLocation.LocationName = newLocationName;
  }




  //Parent functions
  selectParentLocation(selectedLocation: AuditLocation) {
    this.selectedParent = selectedLocation;
  }
  resetParentLocation() {
    this.editingParent = false;
    this.possibleParents = [];
    this.displayParents = [];
    this.selectedParent = new AuditLocation();
    this.minParentLevelID = -1;
    this.topParentLevel = false;
  }
  cancelParentChange() {
    this.editingParent = false;
  }
  getParentLocation(): AuditLocation {
    if (this.selectedLocation.ParentLocationID != -1) {
      if (this.originalData.findIndex(e => e.LocationID == this.selectedLocation.ParentLocationID) != -1) {
        return this.originalData.find(f => f.LocationID == this.selectedLocation.ParentLocationID)!
      }

    }
    return new AuditLocation();
  }
  createPossibleParentList() {
    var currentID = this.selectedLocation.LocationID;
    var levelID = this.selectedLocation.LocationLevelID;
    //Get those at the same level of higher
    var parentList: AuditLocation[] = [];
    this.possibleParents = [];
    if (this.isNewLocation) {
      this.possibleParents = this.originalData;
    } else {
      if (this.originalData.findIndex(x => x.LocationLevelID < levelID) != -1) {
        this.originalData.filter(x => x.LocationLevelID < levelID)!.forEach(e => {
          this.possibleParents.push(e);
        })
      }
    }


    var siblinglist: AuditLocation[] = [];
    if (this.originalData.findIndex(x => x.LocationLevelID == levelID && x.LocationID != currentID) != -1) {
      this.originalData.filter(x => x.LocationLevelID == levelID && x.LocationID != currentID).forEach(e => {
        siblinglist.push(e);
        this.possibleParents.push(e);
      })

      this.possibleParents.push.apply(siblinglist);
      var childList = this.getAllChildren(siblinglist);
      if (this.getAllChildren(siblinglist).length > 0) {
        this.getAllChildren(siblinglist).forEach(e => {
          this.possibleParents.push(e);
        })
      }
    }
    if (this.possibleParents.length > 0) {
      this.setParentList();
      this.parentListAvailable = true;
      this.hasEditParent = true;
    } else {
      this.parentListAvailable = false;
      this.hasEditParent = false;
    }



  }
  getAllChildren(parentList: AuditLocation[]): AuditLocation[] {
    var returnList: AuditLocation[] = [];
    parentList.forEach(x => {
      if (this.originalData.findIndex(y => y.ParentLocationID == x.LocationID) != -1) {
        var childList: AuditLocation[] = [];
        this.originalData.filter(y => y.ParentLocationID == x.LocationID)!.forEach(e => {
          childList.push(e);
          returnList.push(e);
        });
        this.getAllChildren(childList).forEach(e => {
          returnList.push(e);
        })
      }
    })

    return returnList;
  }
  setParentMinLevel() {
    var currentmin = this.possibleParents[0].LocationLevelID;
    this.possibleParents.forEach(e => {
      if (e.LocationLevelID < currentmin) {
        currentmin = e.LocationLevelID
      }
    })
    this.minParentLevelID = currentmin;

  }
  setParentList() {
    this.setParentMinLevel()
    this.displayParents = [];
    this.possibleParents.filter(x => x.LocationLevelID == this.minParentLevelID).forEach(e => {
      this.displayParents.push(e);
    })
    this.topParentLevel = true;
  }
  updateParentList(parentID: number) {
    this.displayParents = [];
    this.possibleParents.filter(x => x.ParentLocationID == parentID).forEach(e => {
      this.displayParents.push(e);
    })
    this.topParentLevel = false;
  }
  switchParentEdit() {
    this.editingParent = !this.editingParent;
  }
  clearParent() {
    this.selectedParent = new AuditLocation();
    this.selectedLocation.ParentLocationID = -1;
    this.switchParentEdit()
    this.hasAvailableParent = false;
  }

  goBack(){
    this.router.navigateByUrl("/settings");
  }
}
